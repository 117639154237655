
@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');
body {
  font-family: 'Public Sans', sans-serif;
}


.dropdown-toggle { outline: 0; }

.btn-toggle {
  padding: .25rem .5rem;
  font-weight: 600;
  color: var(--bs-emphasis-color);
  background-color: transparent;
}


.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

[data-bs-theme="light"] .btn-toggle::before {
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(var(--bs-emphasis-color-rgb), .85);
}
.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a {
  padding: .1875rem .5rem;
  margin-top: .125rem;
  margin-left: 1.25rem;
}

.scrollarea {
  overflow-y: auto;
}


input:checked {
  background-color: #FFC107 !important;
  border: #FFC107 !important;
}
button:focus {
  box-shadow: 0 0 0 0 #FFC107 !important;
}

select:focus {
  box-shadow: 0 0 0 0 #FFC107 !important;
}

.__bee-text {
  color: #d19c00;
}

nav > .pagination {
  background-color: rgb(255, 255, 255) !important;
}

.page-link {
  background-color: #ffffff;
  color: #000000;
}

::selection {
  background-color: #FFC107;
}

::-webkit-scrollbar {
  width: 3px;
}

li > a:hover {
  color: #dea700 !important;
}

::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.form-control:focus, .form-select:focus {
  border-color: #FFC107;
  box-shadow: none;
}

.active > button {
  background-color: #FFC107 !important;
}

ul > li > a {
  color: #000000;
}

.pagination > .disabled {
  background-color: transparent !important;
}