
.loadingArea {
	display: grid;
	place-items: center;
	height: 100vh;
}
.loader div {
	height: 15px;
	width: 15px;
	border-radius: 50%;
	transform: scale(0);
	animation: animate 1.5s ease-in-out infinite;
	display: inline-block;
	margin: .5rem;
}
.loader div:nth-child(0) {
	animation-delay: 0s;
}
.loader div:nth-child(1) {
	animation-delay: 0.2s;
}
.loader div:nth-child(2) {
	animation-delay: 0.4s;
}
.loader div:nth-child(3) {
	animation-delay: 0.6s;
}
.loader div:nth-child(4) {
	animation-delay: 0.8s;
}
.loader div:nth-child(5) {
	animation-delay: 1s;
}
.loader div:nth-child(6) {
	animation-delay: 1.2s;
}
.loader div:nth-child(7) {
	animation-delay: 1.4s;
}
@keyframes animate {
	0%, 100% {
		transform: scale(0.2);
		background-color: #bd8400;
	}
	40% {
		transform: scale(1);
		background-color: #F25330;
	}
	50% {
		transform: scale(1);
		background-color: #F2B900;
	}
}
